@import "_generated-font-mixins.scss";
.rating {
  position: relative;

  display: flex;
  gap: 15px;
  align-items: center;

  margin-bottom: 15px;

  &.header__mobile {
    display: flex;
    flex-direction: column-reverse;
    gap: 5px;
  }

  &__wrapper {
    &__star {
      &::after {
        @include icon-star-fill;

        margin-right: 3px;
        font-size: 16px;
        color: #dcdbdb;
      }

      &:hover {
        &::after {
          cursor: pointer;
          color: #f9d497;
        }
      }
    }

    &__star__big {
      -webkit-tap-highlight-color: transparent;

      -webkit-tap-highlight-color: rgb(0 0 0 / 0%);

      &::after {
        @include icon-star-fill;

        margin-right: 10px;
        margin-left: 10px;
        font-size: 27px;
        color: #dcdbdb;
      }
    }

    &__star__big__fill {
      -webkit-tap-highlight-color: transparent;

      -webkit-tap-highlight-color: rgb(0 0 0 / 0%);

      &::after {
        @include icon-star-fill;

        margin-right: 10px;
        margin-left: 10px;
        font-size: 27px;
        color: #ffaf27;
      }
    }

    &__star__fill {
      &::after {
        @include icon-star-fill;

        margin-right: 3px;
        font-size: 16px;
        color: #ffaf27;
      }

      &:hover {
        &::after {
          cursor: pointer;
          color: #ff9e01;
        }
      }
    }
  }

  &__text {
    font-size: 14px;
    line-height: 16px;
    color: #333;

    &__mobile {
      font-size: 15px;
      line-height: 16px;
      color: #333;
      text-align: center;
    }
  }
}

.error__mobile {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);

  font-size: 14px;
  color: #ef4926;
}

.error {
  position: absolute;
  bottom: -15px;
  left: 0;

  font-size: 14px;
  color: #ef4926;
}
