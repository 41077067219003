@import "_generated-font-mixins.scss";
.text {
  position: relative;

  &__area {
    resize: none !important;

    box-sizing: border-box;
    width: 100%;
    height: 100px;
    margin: 0;
    padding: 8px 12px !important;

    font-size: 15px;
    line-height: 19px;

    background-color: #fff;
    border: 1px solid #ced3d3;
    border-radius: 4px;

    transition: border-color 0.2s;

    &:focus {
      border: 1px solid #ced3d3;
      border-color: #a2acac;
    }

    &:hover {
      border: 1px solid #ced3d3;
      border-color: #a2acac;
    }
  }

  &__count {
    position: relative;

    display: block;

    font-size: 12px;
    color: #999;
    text-align: right;
  }
}

.error {
  position: absolute;
  right: 10px;
  bottom: 28px;

  font-size: 14px;
  color: #ef4926;
}
