@import "_generated-font-mixins.scss";
.reviews__blocked {
  box-sizing: border-box;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 25px;
  padding: 10px 15px;

  font-size: 13px;
  line-height: 19px;
  color: #333;

  background-color: #dbedff;
  border-radius: 4px;

  & > strong {
    color: #005cc3;
  }
}

.reviews__blocked__mobile {
  box-sizing: border-box;
  margin: 20px;
  padding: 10px 15px;

  font-size: 13px;
  line-height: 19px;
  color: #333;

  background-color: #dbedff;
  border-radius: 4px;

  & > strong {
    font-weight: 300;
    color: #333;
    text-decoration: underline;
  }
}
